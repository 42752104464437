.App {

}

.App-header {
    min-height: 10vh;
    min-width: 25vw;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-items: top;
}

.App-main {
    min-height: 80vh;
    min-width: 25vw;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-items: top;
    /* background-image: 'url(abstract-process-bg-4.jpg)';
    background-size: 'cover';
    background-position: 'center center';
    background-repeat: 'no-repeat repeat'; */
}

.App-footer {
    min-height: 20vh;
    min-width: 25vw;
    flex-grow: 1;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-items: top;
    background-color: #2e3542;
    opacity: .75;
}
